import * as Sentry from '@sentry/gatsby';
import packageJson from './package.json';

Sentry.init({
  dsn: 'https://a5720b9abafd4c4b867d9c41074ddbe0@o274448.ingest.sentry.io/6030908',
  environment: 'frontend',
  release: `timetest@${packageJson.version}`,
  sampleRate: 1.0,
  beforeSend(event) {
    let changedEvent: boolean = false;

    // For certain browsers, change the event SeverityLevel
    if (
      ['HeadlessChrome', 'XiaoMi/MiuiBrowser'].some(
        (ele) => window.navigator.userAgent.indexOf(ele) !== -1,
      )
    ) {
      changedEvent = true;
      console.info('User Agent: ', window.navigator.userAgent);
    }

    // We are not interested in ChunkLoadErrors as there is not much we can do (or is there!?)
    if (
      ['ChunkLoadError', 'Loading chunk'].some(
        (needle) =>
          event.message?.includes(needle) ||
          event.exception?.values?.some(
            (excVal) =>
              excVal.type?.includes(needle) || excVal.value?.includes(needle),
          ),
      )
    ) {
      changedEvent = true;
    }

    if (changedEvent) {
      console.warn('Change Sentry event severity level to INFO.');
      event.level = 'info' as Sentry.SeverityLevel;
    }

    return event;
  },
});
